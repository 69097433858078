/* cairo fonts  */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
/* normailize  */
a {
    text-decoration: none;
    color: inherit;
}
.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: auto;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

html[dir='rtl'] input[type='tel'] {
    text-align: right;
}
